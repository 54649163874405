<template>
  <div>
    <header-bar class="q-pb-lg" :title="$t('sign_in')"></header-bar>
    <q-page class="q-pa-md flex flex-center">
      <div class="row justify-center" style="width: 100%">
        <div class="col-12 col-md-4 col-sm-8">
          <q-form @submit="onSubmit" @reset="onReset" class="q-gutter-md">
            <q-input filled v-model="formData.email" type="email" :label="$t('email')">
              <template v-slot:prepend>
                <q-icon name="mail" />
              </template>
            </q-input>

            <q-input filled v-model="formData.password" :type="passwordInvisible ? 'password' : 'text'" :label="$t('password')">
              <template v-slot:prepend>
                <q-icon name="lock" />
              </template>
              <template v-slot:append>
                <q-icon
                  :name="passwordInvisible ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="passwordInvisible = !passwordInvisible"
                />
              </template>
            </q-input>

            <div>
              <q-btn class="full-width" :label="$t('sign_in')" type="submit" color="primary"/>
            </div>

            <p>
              <router-link to="reset-password">{{ $t('forgot_password') }}</router-link> ||
              <router-link to="register"> {{ $t('create_account') }}</router-link>
            </p>
          </q-form>

          <div class="text-center">
            <p class="text-uppercase text-bold">{{ $t('sign_in_with') }}</p>
            <q-separator style="height: 1px" />
            <br />

            <q-spinner v-if="loggingIn" color="primary" size="2em" />
            <custom-svg-icon v-else name="ion-logo-twitter" color="primary" size="lg" @click="twitterLogin()" style="cursor: pointer" />
          </div>
        </div>
      </div>
    </q-page>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

import { Loading } from 'quasar'
import HeaderBar from '../components/HeaderBar'
import CustomSvgIcon from '../components/CustomSvgIcon.vue'
// import { updateDownloads } from '../api'

/* Hide loading spinner If it's visible
    We do this because of our API interceptors, The components that create the
    Loading bar aren't able to hide it before we navigate away from/Destroy them
*/
Loading.hide()

export default {
  name: 'Login',

  components: { HeaderBar, CustomSvgIcon },

  data () {
    return {
      passwordInvisible: true,
      formData: {},
      loggingIn: false
    }
  },

  methods: {

    _finishLogin (signInResult, firebaseUserResponse) {
      // Internal login function that saves the token and the user information.
      // Updates the user's downloads (If necessary) and
      // and navigates to the homepage
      // Save user data to localStorage
      const result = signInResult
      const response = firebaseUserResponse

      localStorage.setItem('token', response.token)
      localStorage.setItem('refresh_token', result.user.refreshToken)
      localStorage.setItem('user', JSON.stringify(result.additionalUserInfo))

      this.loggingIn = false

      // Navigate to homepage after a succesful login
      this.$router.push({ name: 'Home' })

      /* Update user downloads
      updateDownloads({ username: result.additionalUserInfo.username }).then(function (response) {
      }).catch(function (error) {
        self.$errorDialog('An Error occurred: please try again')
        console.log(error)
        self.$sentry.captureException(error)
      }) */
    },

    twitterLogin () {
      this.loggingIn = true
      var self = this

      var provider = new firebase.auth.TwitterAuthProvider()
      firebase.auth().signInWithPopup(provider).then(function (result) {
        result.user.getIdTokenResult(true).then((response) => {
          self._finishLogin(result, response)
        })
      }).catch(function (error) {
        self.loggingIn = false
        self.$errorDialog(self.$t('error.generic'))
        console.log(error)
        self.$sentry.captureException(error)
      })
    },

    onReset () {
      this.formData = {}
    },

    onSubmit () {
      this.loggingIn = true

      const email = this.formData.email
      const password = this.formData.password
      const self = this

      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(function (result) {
          result.user.getIdTokenResult(true).then((response) => {
            self._finishLogin(result, response)
          })
        })
        .catch(function (error) {
          self.loggingIn = false
          self.$q.notify({ type: 'negative', message: error.message })
        })
    }
  }
}
</script>
